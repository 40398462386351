var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('ValidationObserver',{ref:"createForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
var touched = ref.touched;
return [_c('form',{staticClass:"authentication-form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.createNotice)}}},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("To")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.notice.to),expression:"notice.to"}],staticClass:"form-control",attrs:{"name":"to"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.notice, "to", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"all-agencies"}},[_vm._v("All agencies")]),_vm._l((_vm.agencies),function(agency){return _c('option',{key:agency.id,domProps:{"value":("agency-" + (agency.owner.id))}},[_vm._v(_vm._s(agency.name))])})],2)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('TextInput',{attrs:{"name":"title","rules":"required","label":"Title"},model:{value:(_vm.notice.title),callback:function ($$v) {_vm.$set(_vm.notice, "title", $$v)},expression:"notice.title"}})],1),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"content"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',[_vm._v("Content")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.notice.content),expression:"notice.content"}],staticClass:"form-control",class:{ 'is-invalid': failed },attrs:{"name":"content","rows":"3"},domProps:{"value":(_vm.notice.content)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.notice, "content", $event.target.value)}}}),(errors.length)?_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1)])]),_c('b-form-group',[_c('b-button',{attrs:{"variant":"primary","disabled":_vm.loadingCreate || (invalid && touched),"type":"submit"}},[(_vm.loadingCreate)?_c('b-spinner',{attrs:{"small":""}}):_c('span',[_vm._v("Create")])],1)],1)],1)]}}])})],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }