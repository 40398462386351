<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <ValidationObserver
              v-slot="{ handleSubmit, invalid, touched }"
              ref="createForm"
            >
              <form
                class="authentication-form"
                @submit.prevent="handleSubmit(createNotice)"
              >
                <div class="form-group">
                  <label>To</label>
                  <select v-model="notice.to" name="to" class="form-control">
                    <option value="all-agencies">All agencies</option>
                    <option
                      v-for="agency in agencies"
                      :key="agency.id"
                      :value="`agency-${agency.owner.id}`"
                      >{{ agency.name }}</option
                    >
                  </select>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <TextInput
                      v-model="notice.title"
                      name="title"
                      rules="required"
                      label="Title"
                    />
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <ValidationProvider
                        v-slot="{ failed, errors }"
                        rules="required"
                        name="content"
                      >
                        <label>Content</label>
                        <textarea
                          v-model="notice.content"
                          name="content"
                          class="form-control"
                          rows="3"
                          :class="{ 'is-invalid': failed }"
                        ></textarea>
                        <b-form-invalid-feedback v-if="errors.length">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
                <b-form-group>
                  <b-button
                    variant="primary"
                    :disabled="loadingCreate || (invalid && touched)"
                    type="submit"
                  >
                    <b-spinner v-if="loadingCreate" small />
                    <span v-else>Create</span>
                  </b-button>
                </b-form-group>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      notice: {
        title: '',
        content: '',
        to: 'all-agencies',
      },
      loadingCreate: false,
      loadingAgencies: false,
    }
  },

  computed: {
    agencies() {
      return this.$store.getters['agency/allActive'] || []
    },
  },

  mounted() {
    if (this.$store.getters['agency/allActive'] === null) {
      this.getAgencies()
    }
  },

  methods: {
    createNotice() {
      this.loadingCreate = true

      this.$store
        .dispatch('user/createNotification', this.notice)
        .then(() => {
          this.$router.push({ name: 'admin.notifications.index' })
          this.loadingCreate = false
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.createForm.setErrors(err.response.data.errors)
          }
          this.loadingCreate = false
        })
    },

    getAgencies() {
      this.loadingAgencies = true

      this.$store
        .dispatch('agency/getAll', {
          queries: {
            status: 'active',
          },
          page: 'index',
        })
        .then(() => {
          this.loadingAgencies = false
        })
        .catch(() => {
          this.loadingAgencies = false
        })
    },
  },
}
</script>
